export default {
  basic: {},

  addUser: {
    type: 'tertiary',
    prefixIcon: 'addUser',
  },

  cancel: {
    type: 'tertiary',
    text: 'Cancel',
  },

  clear: {
    type: 'tertiary',
    text: 'Clear',
    postfixIcon: 'closeRemove',
    danger: true,
  },

  comment: {
    type: 'primary',
    text: 'Add Comment',
  },

  create: {
    type: 'primary',
    prefixIcon: 'add',
    text: 'Create',
    size: 'medium',
  },

  createDropdown: {
    type: 'primary',
    prefixIcon: 'add',
    text: 'Create New',
    postfixIcon: 'dropdownCaret',
    size: 'medium',
  },

  delete: {
    type: 'primary',
    prefixIcon: 'delete',
    text: 'Delete',
    danger: true,
  },

  download: {
    type: 'primary',
    prefixIcon: 'download',
  },

  dropdown: {
    postfixIcon: 'dropdownCaret',
    type: 'secondary',
  },

  icon: {
    type: 'secondary',
    shape: 'circle',
    size: 'medium',
  },

  link: {
    type: 'tertiary',
    prefixIcon: 'link',
    text: 'Link',
    size: 'medium',
  },

  remove: {
    type: 'tertiary',
    prefixIcon: 'cancel',
    danger: true,
    shape: 'circle',
    size: 'medium',
  },

  save: {
    type: 'primary',
    text: 'Save',
  },

  sendMail: {
    type: 'primary',
    prefixIcon: 'send',
    text: 'Send',
  },

  // relationships
  addRelationship: {
    type: 'secondary',
    prefixIcon: 'add',
  },

  removeRelationship: {
    type: 'secondary',
    prefixIcon: 'closeRemove',
    danger: true,
  },

  preview: {
    type: 'secondary',
    prefixIcon: 'show',
    text: 'Preview',
  },

  select: {
    type: 'secondary',
    prefixIcon: 'selected',
    size: 'medium',
  },

  deselect: {
    type: 'secondary',
    prefixIcon: 'unselected',
    size: 'medium',
  },

  collapse: {
    type: 'secondary',
    prefixIcon: 'collapseVertical',
    size: 'medium',
  },

  expand: {
    type: 'secondary',
    prefixIcon: 'expandVertical',
    size: 'medium',
  },

  upload: {
    type: 'secondary',
    prefixIcon: 'uploadImport',
  },

  // icon buttons
  downloadIcon: {
    type: 'primary',
    prefixIcon: 'download',
    size: 'medium',
  },

  historyIcon: {
    type: 'secondary',
    prefixIcon: 'versionHistory',
    size: 'medium',
  },

  closeIcon: {
    type: 'icon',
    prefixIcon: 'closeRemoveLarge',
    size: 'medium',
  },

  createIcon: {
    type: 'icon',
    prefixIcon: 'add',
    size: 'medium',
  },

  sendMailIcon: {
    type: 'primary',
    prefixIcon: 'send',
    size: 'medium',
  },
}
