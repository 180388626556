export default {
  mycontrols: {
    error: null,
    frameworks: [],
    domains: [],
    controlsDomains: [],
    allControlGroups: [],
    controlGroupsTags: [],
    evidenceTags: [],
    controlGroups: {
      currentPage: 1,
      data: [],
      numberPages: 1,
      perPage: 1e5, // WARNING: If updating to paginate data, must also update Donut/Bubble getters in getters/mycontrols.js
      totalCount: 0,
    },
    frameworkControls: [],
    dashboard: {
      allTags: [],
      controlGroups: [],
      events: {
        controls: [],
        evidence: [],
      },
      tasks: {
        controls: [],
        evidence: [],
      },
      evidence: [],
      domainCounts: {},
      controlStatusCounts: {},
      evidenceStatusCounts: {},
      controlGroupsStatusCountsOverTime: {},
      totalsDonutColors: {
        complete: 'rgba(71, 182, 88, 0.85)',
        incomplete: 'rgba(211, 211, 211, 1)',
      },
      totalsDonutLabels: {
        operational: {
          complete: 'Complete',
          incomplete: 'Incomplete',
        },
        evidence: {
          complete: 'Current',
          incomplete: 'Not Current',
        },
      },
    },
    evidence: {
      currentPage: 1,
      data: [],
      numberPages: 1,
      perPage: 1e5, // WARNING: If updating to paginate data, must also update Donut/Bubble getters in getters/mycontrols.js
      totalCount: 0,
    },
    filters: {
      controlGroups: {
        // only show applicable control groups by default
        applicability: [true],
        implementationStatus: [],
        operationalStatus: [],
        evidence: [],
        domain: [],
        framework: [],
        scope: [],
        owner: [],
        tag: [],
        status: [],
      },
      dashboard: {
        // only show applicable control groups/evidence by default
        applicability: [true],
        domain: [],
        framework: [],
        scope: [],
        owner: [],
        tag: [],
        // date_start: null,
        // date_end: moment().add(14, 'day'),
      },
      events: {
        owner: [],
        status: [null, 'on_track', 'behind', 'overdue', 'not_started'],
      },
      evidence: {
        // only show applicable evidence by default
        applicability: [true],
        status: [],
        domain: [],
        framework: [],
        scope: [],
        assignee: [],
        tag: [],
        expiration: [],
      },
      frameworkControls: {
        // only show applicable controls by default
        applicability: [true],
        domain: [],
        scope: [],
        controlGroup: [],
      },
      tasks: {
        criticality: [],
        user: [],
        status: ['on_track', 'behind', 'overdue', 'not_started'],
        tags: [],
      },
      risks: {
        riskScore: [],
        decision: [],
        status: ['not_started', 'in_progress', 'behind_schedule', 'on_track'],
      },
    },
    currentViewId: null,
    filteredViews: [],
    sorting: {
      tasks: {},
      events: {},
    },

    applicabilityOptions: [
      { value: true, text: 'Applicable', variant: 'primary' },
      { value: false, text: 'Not Applicable', variant: 'gray' },
    ],
    frameworkStatuses: [
      { value: true, text: 'Active', variant: 'success' },
      { value: false, text: 'Inactive', variant: 'danger' },
    ],

    operationalStatuses: [],
    implementationStatuses: [],
    evidenceStatuses: [],
    evidenceFileStatuses: [],

    //function get_file_expiration_date in the database is hard-coded to look for these values (case-sensitive)
    availablePeriods: [
      { value: 'D', text: 'Day(s)' },
      { value: 'W', text: 'Week(s)' },
      { value: 'M', text: 'Month(s)' },
      { value: 'Q', text: 'Quarter(s)' },
      { value: 'Y', text: 'Year(s)' },
    ],

    evidenceExpirationOptions: [
      { value: -28, text: 'Expired For 4+ Weeks' },
      { value: -21, text: 'Expired For 3+ Weeks' },
      { value: -14, text: 'Expired For 2+ Weeks' },
      { value: 7, text: 'Next Week' },
      { value: 14, text: 'Next 2 Weeks' },
      { value: 30, text: 'Next Month' },
      { value: 60, text: 'Next 2 Months' },
      { value: 90, text: 'Next Quarter' },
      { value: 'none', text: 'No Expiration' },
    ],

    currentControl: {
      changeLog: [],
      collaborators: [],
      comments: [],
      control: {},
      events: [],
      evidence: [],
      mappedControls: [],
      owners: [],
      policies: [],
      risks: [],
      scopes: [],
      subControls: [],
      tags: [],
      tasks: [],
    },

    currentEvidence: {
      assignees: [],
      changeLog: [],
      collaborators: [],
      comments: [],
      controls: [],
      events: [],
      evidence: {},
      files: [],
      links: [],
      tags: [],
      tasks: [],
      typicalEvidenceFiles: [],
    },

    currentFramework: {
      framework: {
        id: null,
      },
      versions: [],
      controls: {
        currentPage: 1,
        data: [],
        numberPages: 1,
        // todo: reduce once pagination is implemented
        perPage: 500,
        totalCount: 0,
      },
    },

    currentFrameworkControl: {
      control: {},
      changeLog: [],
      controlGroups: [],
    },

    lastSelectedProject: null,
    lastSelectedProjectSection: null,
  },
}
