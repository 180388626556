<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'Status',
      }
    },

    computed: {
      ...mapState({
        filters(state) {
          return state.assessments.currentProjectFilters[this.$route.params.tab]
        },
        options(state) {
          let ids = [this.$route.params.assessmentId]
          if (
            this.filters.includedChildren &&
            this.filters.includedChildren.length > 0
          ) {
            ids = ids.concat(this.filters.includedChildren)
          }
          const allRawStatuses = ids
            .map((id) => {
              const { rawStatuses } = state.assessmentStatusesAndColors(
                id,
                this.$route.params.tab
              )
              return rawStatuses
            })
            .flat(1)

          return allRawStatuses.map((s) => ({
            text: s.text,
            value: s.value,
            variant: s.variant,
          }))
        },
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.status')
              .includes(opt.value)
          )
        },
      }),
    },
  }
</script>
