import ApiAssessments from '../../factories/ApiAssessments'
import ApiExtensions from '../../factories/ApiExtensions'
import ApiTags from '../../factories/ApiTags'
import ApiPentesting from '../../factories/ApiPentesting'

export default {
  async initAssessments({ dispatch, state }, reset = state.globalReset) {
    await Promise.all([
      dispatch('getEngagementTypes', reset),
      dispatch('getAssessmentStatuses', reset),
      dispatch('getGapTypes', reset),
    ])
  },

  async getAssessments(
    { commit, state },
    { status, projectType, moduleType, reset = state.globalReset }
  ) {
    if (state.assessments.projects.length > 0 && !reset) return

    const { projects, additionalTemplates, hierarchy } =
      await ApiAssessments.getProjects({
        status,
        type: projectType,
        moduleType,
      })

    commit(
      'SET_ASSESSMENT_PROJECTS',
      projects.concat(
        (additionalTemplates || []).map((p) => ({
          ...p,
          name: `${p.name} (Global Template)`,
        }))
      )
    )
    commit('SET_ASSESSMENT_ADDITIONAL_TEMPLATES', additionalTemplates)
    commit('SET_ASSESSMENT_PROJECT_HIERARCHY', hierarchy)
  },

  async getAssessmentCurrentProject(
    { commit },
    { id, tab = 'evidence', filters = null }
  ) {
    const { project, cachedFilters, cats_reqs } =
      await ApiAssessments.getProject(id, tab, filters)

    commit('SET_CURRENT_PROJECT', project)
    commit('SET_CURRENT_PROJECT_CATEGORIES', cats_reqs)
    if (cachedFilters) {
      commit('SET_CURRENT_PROJECT_FILTERS', {
        tab,
        filters: cachedFilters,
      })
    }
    return {
      project,
      cachedFilters,
      cats_reqs,
    }
  },

  async getAssessmentDashboardTags(
    { commit, state },
    reset = state.globalReset
  ) {
    if (
      state.assessments.dashboardTags &&
      state.assessments.dashboardTags.length > 0 &&
      !reset
    )
      return
    const res = await ApiTags.getAllTagsByEntityTypes([
      'assessment_project_controls',
      'assessment_project_requests',
    ])
    commit('SET_ASSESSMENT_DASHBOARD_TAGS', res.tags)
  },

  async getProjectDashboardData({ commit }, { id, filters = null }) {
    const { data } = await ApiAssessments.getProjectDashboardData(id, filters)

    commit('SET_DASHBOARD_DATA', data)
    return {
      data,
    }
  },

  async getAssessmentCategories({ commit }, { id, tab = 'evidence' }) {
    const { cachedFilters, cats_reqs } =
      await ApiAssessments.getCategoriesAndRequests(id, tab)
    commit('SET_CURRENT_PROJECT_CATEGORIES', cats_reqs)
    if (cachedFilters) {
      commit('SET_CURRENT_PROJECT_FILTERS', {
        tab,
        filters: cachedFilters,
      })
    }
    return {
      cachedFilters,
      cats_reqs,
    }
  },

  async getAssessmentCurrentControl({ commit, state }, id) {
    id = id || state.assessments.currentControl.id
    if (!id) return
    const info = await ApiAssessments.getControl(id)
    commit('SET_CURRENT_PROJECT_CONTROL', info)
  },

  async getAssessmentCurrentControlTestInfo(
    { commit, state },
    { controlId, testInfoId }
  ) {
    controlId = controlId || state.assessments.currentControl.id
    const { testInfo } = await ApiAssessments.getControlTestInfo(
      controlId,
      testInfoId
    )
    if (testInfoId) {
      commit('SET_CURRENT_CONTROL_TEST_INFO', {
        testInfo: testInfo[0],
        testInfoId,
      })
    } else {
      commit('SET_CURRENT_CONTROL_TEST_INFO', { testInfo })
    }
  },

  async getAssessmentCurrentRequest({ commit }, reqId) {
    const info = await ApiAssessments.getRequest(reqId)
    commit('SET_CURRENT_PROJECT_REQUEST', info)
  },

  async getEngagementTypes({ commit, state }, reset = state.globalReset) {
    if (
      state.assessments.engagementTypes &&
      state.assessments.engagementTypes.length > 0 &&
      !reset
    )
      return

    const { types } = await ApiAssessments.getEngagementTypes()
    commit('SET_ASSESSMENTS_ENGAGEMENT_TYPES', types)
  },

  async getGapTypes({ commit, state }, reset = state.globalReset) {
    if (Object.keys(state.assessments.projectGapTypes).length > 0 && !reset)
      return

    const { types } = await ApiAssessments.getGapTypes()
    commit('SET_ASSESSMENTS_GAP_TYPES', types)
  },

  async getAssessmentStatuses({ commit, state }, reset = state.globalReset) {
    if (
      state.assessments.allRequestStatuses &&
      state.assessments.allRequestStatuses.length > 0 &&
      !reset
    )
      return

    const {
      controlsStatuses,
      requestStatusesAll,
      requestStatusesUser,
      projectStatuses,
    } = await ApiAssessments.statuses()

    commit('SET_ASSESSMENTS_ALL_STATUSES', [
      requestStatusesAll,
      controlsStatuses,
      requestStatusesUser,
      projectStatuses,
    ])
  },

  async getAssessmentKeyContactRolesByProject({ commit }, projectId) {
    const { contacts } = await ApiAssessments.getKeyContacts(projectId)
    commit('SET_KEY_CONTACT_ROLES', { projectId, contacts })
  },

  async getIndependence({ commit }) {
    const { independent } = await ApiAssessments.getIndependence()
    commit('SET_INDEPENDENCE_STATUS', independent)
  },

  async getProjectTeam({ commit }, projectId) {
    const { teamMembers } = await ApiAssessments.getProjectTeamMembers(
      projectId
    )
    commit('SET_CURRENT_PROJECT_TEAM', teamMembers)
  },

  async getProjectAutomations({ commit }, projectId) {
    const { automations } = await ApiExtensions.getAssessmentAutomations(
      projectId
    )
    commit('SET_CURRENT_PROJECT_AUTOMATIONS', automations)
  },

  async getProjectCharting(
    { state, commit },
    { projectId, filters, reset = false }
  ) {
    if (state.assessments.charting.gapSummary && !reset) return

    const info = await ApiAssessments.getChartingKpis(projectId, filters)
    commit('SET_CURRENT_PROJECT_CHARTING', info)
  },

  async getFormalFindingsInfo({ commit, state, getters }, projectIds) {
    // TODO: should we add reset functionality here?
    // const project = state.assessments.currentProject
    // if (project && project.id === projectId && state.assessments.currentFormalFindings.length > 0)
    //   return

    const isPentestModule = getters.routeNamespace === 'pentesting'

    const { findings, statuses } = isPentestModule
      ? await ApiPentesting.getFormalFindings(projectIds)
      : await ApiAssessments.getFormalFindings(projectIds)

    commit('SET_CURRENT_FORMAL_FINDINGS', findings)
    commit(
      'SET_CURRENT_FORMAL_FINDINGS_STATUSES',
      statuses.map((s) => ({ ...s, color: state.dashboard.colors[s.color] }))
    )
  },

  async getGlobalTemplateFormalFindings({ commit, state }) {
    const { findings, statuses } =
      await ApiAssessments.getFormalFindingGlobalTemplates()
    commit('SET_CURRENT_FORMAL_FINDINGS', findings)
    commit(
      'SET_CURRENT_FORMAL_FINDINGS_STATUSES',
      statuses.map((s) => ({ ...s, color: state.dashboard.colors[s.color] }))
    )
  },

  async getCurrentProjectMappingsCount({ state, commit }, { projectIds }) {
    // don't fetch if we already have counts
    if (
      Object.keys(state.assessments.currentProjectRequestMappingsCount).length >
      0
    )
      return

    const response = await ApiAssessments.getRequestMappingsCount(projectIds)
    if (response.map) commit('SET_CURRENT_MAPPINGS_COUNT', response.map)
    return response
  },

  async getFrameworkKpis({ commit }) {
    const { kpis } = await ApiAssessments.getFrameworkKpis()
    commit('SET_FRAMEWORK_KPIS', kpis)
  },
}
