import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getAllProjects(isDeleted = false, showArchivedProjects = false) {
    const response = await R3sFetch(
      `/api/1.0/projects/get?isDeleted=${
        isDeleted || ''
      }&showArchivedProjects=${showArchivedProjects}`
    )
    return handleFetchResponse(response)
  },

  async getProject(id) {
    const response = await R3sFetch(`/api/1.0/projects/project/get?id=${id}`)
    return handleFetchResponse(response)
  },

  async getProjectGroups(id) {
    const response = await R3sFetch(
      `/api/1.0/projects/groups/get?projectId=${id}`
    )
    return handleFetchResponse(response)
  },

  async createOrUpdateProject(info) {
    const response = await R3sFetch(`/api/1.0/projects/project/post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return handleFetchResponse(response)
  },

  async updateProjectOwners(projectId, userIds) {
    const response = await R3sFetch(`/api/1.0/projects/project/owners/post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: projectId, userIds }),
    })
    return handleFetchResponse(response)
  },

  async removeProjectOwner(projectId, userId) {
    const response = await R3sFetch(`/api/1.0/projects/project/owners/remove`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: projectId, userId: userId }),
    })
    return handleFetchResponse(response)
  },

  async getAllTasks(
    projectId,
    filters = null,
    sorting = null,
    page = 1,
    perPage = 20
  ) {
    const response = await R3sFetch(
      `/api/1.0/projects/tasks/get?projectId=${projectId}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}&sorting=${encodeURIComponent(JSON.stringify(sorting))}&page=${
        page || 1
      }&perPage=${perPage || 20}`
    )
    return handleFetchResponse(response)
  },

  async getTask(taskId) {
    const response = await R3sFetch(`/api/1.0/projects/task/get?id=${taskId}`)
    return handleFetchResponse(response)
  },

  async getSubtasks(taskId) {
    const response = await R3sFetch(
      `/api/1.0/projects/task/subtasks/get?taskId=${taskId}`
    )
    return handleFetchResponse(response)
  },

  async createOrUpdateTask(info) {
    const response = await R3sFetch(`/api/1.0/projects/task/post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return handleFetchResponse(response)
  },

  async bulkUploadTasks(tasks) {
    const response = await R3sFetch(`/api/1.0/projects/tasks/post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(tasks),
    })
    return handleFetchResponse(response)
  },

  async moveTask(info) {
    const response = await R3sFetch(`/api/1.0/projects/task/move`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return handleFetchResponse(response)
  },

  async getTaskGroup(taskGroupId) {
    const response = await R3sFetch(
      `/api/1.0/projects/group/get?id=${taskGroupId}`
    )
    return handleFetchResponse(response)
  },

  async createOrUpdateTaskGroup(info) {
    const response = await R3sFetch(`/api/1.0/projects/group/post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return handleFetchResponse(response)
  },

  async getStatuses() {
    const response = await R3sFetch(`/api/1.0/projects/statuses`)
    return handleFetchResponse(response)
  },

  async downloadProject(projectId) {
    const response = await R3sFetch(
      `/api/1.0/projects/project/download?projectId=${projectId}`
    )
    return handleFetchResponse(response)
  },

  async downloadSelectedTasks(taskIds) {
    const response = await R3sFetch(
      `/api/1.0/projects/tasks/download?taskIds=${JSON.stringify(taskIds)}`
    )
    return handleFetchResponse(response)
  },

  async addTaskUploadUser(taskId, userId) {
    const response = await R3sFetch(`/api/1.0/projects/tasks/upload/user/add`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ taskId, userId }),
    })
    return handleFetchResponse(response)
  },

  async removeTaskUploadUser(taskId, userId) {
    const response = await R3sFetch(
      `/api/1.0/projects/tasks/upload/user/remove`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ taskId, userId }),
      }
    )
    return handleFetchResponse(response)
  },

  async sendTaskUploadUserEmail(taskId, userId) {
    const response = await R3sFetch(
      `/api/1.0/projects/tasks/upload/users/email`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ taskId, userId }),
      }
    )
    return handleFetchResponse(response)
  },

  async updateTaskOwners(taskId, userIds) {
    const response = await R3sFetch(`/api/1.0/projects/task/owners/post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: taskId, userIds }),
    })
    return handleFetchResponse(response)
  },

  async removeTaskOwner(taskId, userId) {
    const response = await R3sFetch(`/api/1.0/projects/task/owners/delete`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ taskId, userId }),
    })
    return handleFetchResponse(response)
  },

  async updateTaskCollaborators(taskId, userIds) {
    const response = await R3sFetch(
      `/api/1.0/projects/task/collaborators/post`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: taskId, userIds }),
      }
    )
    return handleFetchResponse(response)
  },

  async removeTaskCollaborator(taskId, userId) {
    const response = await R3sFetch(
      `/api/1.0/projects/task/collaborators/delete`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ taskId, userId }),
      }
    )
    return handleFetchResponse(response)
  },

  async updateTaskComment(info) {
    const response = await R3sFetch(`/api/1.0/projects/task/comment/post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return handleFetchResponse(response)
  },

  async updateTaskFile(info) {
    const response = await R3sFetch(`/api/1.0/projects/task/file/post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return handleFetchResponse(response)
  },

  async checkIfMappingExists(projectId, table, entityId) {
    const response = await R3sFetch(
      `/api/1.0/projects/task/mapping/check?projectId=${projectId}&table=${table}&entityId=${entityId}`
    )
    return handleFetchResponse(response)
  },

  async mapTaskToEntity(info) {
    const response = await R3sFetch(`/api/1.0/projects/task/map`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return handleFetchResponse(response)
  },

  async unmapTaskFromEntity(info) {
    const response = await R3sFetch(`/api/1.0/projects/task/unmap`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return handleFetchResponse(response)
  },

  // async bulkUpload(projectId, fileName) {
  //   const response = await R3sFetch(`/api/1.0/projects/bulk/upload`, {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify({ id: projectId, filename: fileName }),
  //   })
  //   return handleFetchResponse(response)
  // },

  async updateGroupOrdering(projectId, groupIds, startIndex = 0) {
    const response = await R3sFetch(`/api/1.0/projects/groups/reorder`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ projectId, groupIds, startIndex }),
    })
    return handleFetchResponse(response)
  },

  async updateTaskOrdering(projectId, taskIds, startIndex = 0) {
    const response = await R3sFetch(`/api/1.0/projects/tasks/reorder`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ projectId, taskIds, startIndex }),
    })
    return handleFetchResponse(response)
  },

  // async updateTaskMetric(info) {
  //   const response = await R3sFetch(
  //     `/api/1.0/projects/item/metric/update`,
  //     {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify(info),
  //     }
  //   )
  //   return handleFetchResponse(response)
  // },

  async generateStatusReport(info, signal = null) {
    const response = await R3sFetch(
      `/api/1.0/projects/ongoing/report/generate`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
        signal,
      }
    )
    return handleFetchResponse(response)
  },

  async createProjectFromSelfAssessment(questionnaireId) {
    const response = await R3sFetch(
      `/api/1.0/projects/project/create/from/questionnaire`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ questionnaireId }),
      }
    )
    return handleFetchResponse(response)
  },

  async generateWalkthrough(
    controlIds,
    generateMaster = true,
    generateIndividual = true
  ) {
    const queries = []
    queries.push(`controlIds=${controlIds.join(',')}`)
    if (generateMaster) queries.push('generateMaster=true')
    if (generateIndividual) queries.push('generateIndividual=true')
    const response = await R3sFetch(
      `/api/1.0/assessments/walkthrough/generate?${queries.join('&')}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }
    )
    return handleFetchResponse(response)
  },

  async getAllProjectManagementOwners() {
    const response = await R3sFetch(`/api/1.0/projects/tasks/owners/all`)
    return handleFetchResponse(response)
  },

  async getAllProjectManagementGroups() {
    const response = await R3sFetch(`/api/1.0/projects/tasks/groups/all`)
    return handleFetchResponse(response)
  },

  async getAllProjectManagementTags() {
    const response = await R3sFetch(`/api/1.0/projects/tasks/tags/all`)
    return handleFetchResponse(response)
  },

  async getAllProjectManagementScopes() {
    const response = await R3sFetch(`/api/1.0/projects/tasks/scopes/all`)
    return handleFetchResponse(response)
  },

  async getDashboardTaskCount(filters) {
    const response = await R3sFetch(
      `/api/1.0/projects/dashboard/count?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return handleFetchResponse(response)
  },

  async getDashboardOwnerCounts(filters) {
    const response = await R3sFetch(
      `/api/1.0/projects/dashboard/owner/counts?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return handleFetchResponse(response)
  },

  async getDashboardCriticalityCounts(filters) {
    const response = await R3sFetch(
      `/api/1.0/projects/dashboard/criticality/counts?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return handleFetchResponse(response)
  },

  async getDashboardTaskAging(filters) {
    const response = await R3sFetch(
      `/api/1.0/projects/dashboard/aging/counts?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return handleFetchResponse(response)
  },

  async getDashboardByXCounts(groupBy, countType, filters) {
    const response = await R3sFetch(
      `/api/1.0/projects/dashboard/x/counts?groupBy=${groupBy}&countType=${countType}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return handleFetchResponse(response)
  },

  async getDashboardTasksBehindSchedule(filters) {
    const response = await R3sFetch(
      `/api/1.0/projects/dashboard/tasks/behind?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return handleFetchResponse(response)
  },

  async getDashboardTaskHistory(filters) {
    const response = await R3sFetch(
      `/api/1.0/projects/dashboard/tasks/history?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return handleFetchResponse(response)
  },
}
