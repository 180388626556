import AssessmentActions from './assessments'
import ExtensionsActions from './extensions'
import InventoriesActions from './inventories'
import Jira from './jira'
import FeedbackActions from './feedback'
import Files from './files'
import ComplianceActions from './compliance'
import MyControls from './mycontrols'
import ProjectActions from './projects'
import ProgramActions from './program'
import QuestionnaireActions from './questionnaire'
import ReportingActions from './reporting'
import RiskRegisterActions from './riskregister'
import SelfAssessmentActions from './selfassessments'
import Settings from './settings'
import VendorManagementActions from './vendormanagement'
import VulnerabilityActions from './vulnerability'
import WikiActions from './wiki'

import ApiAdmin from '../../factories/ApiAdmin'
import ApiAuth from '../../factories/ApiAuth'
import ApiAws from '../../factories/ApiAws'
import ApiGlobal from '../../factories/ApiGlobal'
import ApiTags from '../../factories/ApiTags'
import ApiTeams from '../../factories/ApiTeams'
import ApiUsers from '../../factories/ApiUsers'
import ApiMessaging from '../../factories/ApiMessaging'

import moment from 'moment'
import ApiReports from '../../factories/ApiReports'

export default {
  ...AssessmentActions,
  ...ComplianceActions,
  ...ExtensionsActions,
  ...FeedbackActions,
  ...Files,
  ...InventoriesActions,
  ...Jira,
  ...MyControls,
  ...ProjectActions,
  ...ProgramActions,
  ...QuestionnaireActions,
  ...ReportingActions,
  ...RiskRegisterActions,
  ...SelfAssessmentActions,
  ...Settings,
  ...VendorManagementActions,
  ...VulnerabilityActions,
  ...WikiActions,

  async init({ commit, dispatch, state }) {
    // state.isInitProcessing is a debounce flag that prevents this `init` action
    // from dispatching more than once at a time. currently init is dispatched
    // on root component create and when route changes occur which could overlap.
    if (state.isInitProcessing) return

    try {
      commit('SET_INIT_PROCESSING', true)

      // What's up yo?! Are you adding something to the following list?
      //
      // well
      //
      //             uuuuuuuuuuuuuuuuuuuu
      //           u" uuuuuuuuuuuuuuuuuu "u
      //         u" u$$$$$$$$$$$$$$$$$$$$u "u
      //       u" u$$$$$$$$$$$$$$$$$$$$$$$$u "u
      //     u" u$$$$$$$$$$$$$$$$$$$$$$$$$$$$u "u
      //   u" u$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$u "u
      // u" u$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$u "u
      // $ $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ $
      // $ $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ $
      // $ $$$" ... "$...  ...$" ... "$$$  ... "$$$ $
      // $ $$$u `"$$$$$$$  $$$  $$$$$  $$  $$$  $$$ $
      // $ $$$$$$uu "$$$$  $$$  $$$$$  $$  """ u$$$ $
      // $ $$$""$$$  $$$$  $$$u "$$$" u$$  $$$$$$$$ $
      // $ $$$$....,$$$$$..$$$$$....,$$$$..$$$$$$$$ $
      // $ $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ $
      // "u "$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$" u"
      //   "u "$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$" u"
      //     "u "$$$$$$$$$$$$$$$$$$$$$$$$$$$$" u"
      //       "u "$$$$$$$$$$$$$$$$$$$$$$$$" u"
      //         "u "$$$$$$$$$$$$$$$$$$$$" u"
      //           "u """""""""""""""""" u"
      //             """"""""""""""""""""
      //
      // make sure you know what you're doing because adding to
      // this list could brick fullCircle (or at least make people think
      // it's bricked). You need to add any API requests to the whitelist
      // in @/src/libs/Routes.ts of any new actions added here.
      const [sessionInfo, teamUserInfo, userNavItems, permissionCode] =
        await Promise.all([
          dispatch('getUserSession'),
          dispatch('getTeamUsers'),
          dispatch('getUserNavItems'),
          dispatch('getUserPermissionCode'),

          // Keep this in this block because a few components depend on this being
          // populated in their created() hooks. Once we identify all places and
          // make necessary changes to prevent requiring this we can move this
          // back down.
          dispatch('getUserPersonalizations'),

          // TODO: determine why this endpoint is taking so long to load
          // and uncomment. The backend is just reading from redis and mapping/reducing
          // the list, so need to find the bottleneck soon.
          // Moved to async below, so waiting shouldn't matter anymore.
          // dispatch('getUsersInPage'),
        ])

      const user = sessionInfo.session.user

      commit('SET_SESSION_INFO', {
        session: sessionInfo.session,
        user: user,
        nav: userNavItems,
        permission: permissionCode,
        team: teamUserInfo,
      })

      dispatch('getTeamUserModulePermissions')
      dispatch('getUserAlerts')
      dispatch('getQueueSummary')
      dispatch('getTeamAndInternalUsers')
      dispatch('getTeamDisabledUsers')
      dispatch('getTeamUploadUsers')
      dispatch('getDefaultVulnerabilityColumns')
      dispatch('getGlobalInternalUsers')
      dispatch('getModulePermissionList')
      dispatch('getTags')
      dispatch('getR3STags')
      dispatch('getUnreadSecureMessageCount')
      dispatch('getAppOauthBindings')
      dispatch('getFeatureFlags')
      dispatch('getUsersInPage')

      const fullPath = window.vueRouter.currentRoute.value.fullPath
      const isLoggedIn = !!user
      commit('CHECK_LOGGED_IN', isLoggedIn)

      if (isLoggedIn) {
        if (!user.is_verified) {
          if (!/^\/verification\//.test(fullPath))
            return window.vueRouter.push('/verification/pending')
        }

        // MFA check
        if (
          user.two_factor_enabled &&
          !sessionInfo.session.two_factor_authenticated
        ) {
          commit('CHECK_LOGGED_IN', false)
          if (!/^\/mfa/.test(fullPath)) {
            return window.vueRouter.push('/mfa')
          } else {
            // Uses window.location.href redirect in order to intiate all init
            // actions again since vueRouter.push will not call init actions
            if (/^\/login/.test(fullPath)) {
              // TODO make call to set redirect route
              await ApiGlobal.setRedirectUrl(window.location.pathname)
              return (window.location.href = '/') // return window.vueRouter.push('/')
            }
          }
        }
      } else {
        // Check fullPath and current window.location.pathname
        if (
          !(
            /^\/login/.test(fullPath) ||
            /^\/autherror/.test(fullPath) ||
            /^\/success/.test(fullPath) ||
            /^\/mfa/.test(fullPath) ||
            /^\/verify\/password\/reset/.test(fullPath)
          ) &&
          !(
            /^\/login/.test(window.location.pathname) ||
            /^\/autherror/.test(window.location.pathname) ||
            /^\/success/.test(window.location.pathname) ||
            /^\/mfa/.test(window.location.pathname) ||
            /^\/verify\/password\/reset/.test(window.location.pathname)
          )
        ) {
          commit('SET_REDIRECT_URL', window.location.pathname)
          return window.vueRouter.push('/login')
        }
      }

      const currentTeam = sessionInfo.session.current_team
      if (
        !/^\/verification\//.test(fullPath) && // Let verification screen take priority
        currentTeam &&
        !currentTeam.eula_acceptance_log_id &&
        user.user_type === 'external' && // Don't restrict internal users
        moment(currentTeam.created_at).isAfter(moment('2022-06-24')) // Adrian release
      ) {
        return window.vueRouter.push('/eula')
      }
      commit('SET_PATH', fullPath)
    } finally {
      commit('APP_NO_LONGER_LOADING')
      commit('SET_INIT_PROCESSING', false)
    }
  },

  async getActivePgpKey({ commit, state }, reset = state.globalReset) {
    if (state.pgp.key && !reset) return

    const { keys } = await ApiMessaging.getActivePgpKeys()
    commit('SET_ACTIVE_PGP_KEY', keys[0])
  },

  async getUsersInPage({ commit }) {
    const { users } = await ApiUsers.getUsersInPage()
    commit('SET_USERS_IN_PAGE', users)
  },

  async getQueueSummary({ commit, state }, reset = state.globalReset) {
    if (state.queueSummary && !reset) return

    const queueInfo = await ApiUsers.getQueueSummary()
    commit('SET_QUEUE_SUMMARY', queueInfo)
  },

  async getUnreadSecureMessageCount(
    { commit, state },
    reset = state.globalReset
  ) {
    if (
      state.unreadSecureMessages &&
      // state.unreadSecureMessages.length > 0 &&
      !reset
    ) {
      return
    }

    const { newMessages } = await ApiMessaging.getUnreadCount()
    commit('SET_UNREAD_SECURE_MESSAGES', newMessages)
  },

  async getGlobalInternalUsers({ commit, state }, reset = state.globalReset) {
    if (state.internalUsers.length > 0 && !reset) return

    const { users } = await ApiGlobal.getInternalUsers()
    commit('SET_GLOBAL_INTERNAL_USERS', users)
  },

  async getTeamUsers({ state }, reset = state.globalReset) {
    if (state.team && state.team.users.length > 0 && !reset)
      return { users: state.team.users }

    return await ApiTeams.getTeamUsers()
  },

  async getTeamAndInternalUsers({ commit, state }, reset = state.globalReset) {
    if (
      state.team &&
      state.team.teamAndInternalUsers &&
      state.team.teamAndInternalUsers.length > 0 &&
      !reset
    ) {
      return
    }

    const { users } = await ApiTeams.getTeamUsers(true)
    commit('SET_TEAM_AND_INTERNAL_USERS', users)
  },

  async getTeamDisabledUsers({ commit, state }, reset = state.globalReset) {
    if (
      state.team &&
      state.team.teamDisabledUsers &&
      state.team.teamDisabledUsers.length > 0 &&
      !reset
    ) {
      return
    }

    const { data } = await ApiUsers.getUsersInTeam(
      { includeDisabled: true },
      1,
      10e9
    )
    const disabledUsers = data.filter((u) => u.is_disabled)
    commit('SET_TEAM_DISABLED_USERS', disabledUsers)
  },

  async getTeamUploadUsers({ commit, state }, reset = state.globalReset) {
    if (state.team?.uploadPortalUsers?.length > 0 && !reset) {
      return
    }

    const { users } = await ApiTeams.getTeamUploadUsers()
    commit('SET_TEAM_UPLOAD_PORTAL_USERS', users)
  },

  // async getTeamPolicyPortalUsers({ commit, state }, reset = state.globalReset) {
  //   if (state.team?.policyPortalPortalUsers?.length > 0 && !reset) {
  //     return
  //   }

  //   const { users } = await ApiTeams.getTeamPolicyPortalUsers()
  //   commit('SET_TEAM_POLICY_PORTAL_USERS', users)
  // },

  async getTeamDistributionGroups(
    { commit, state },
    reset = state.globalReset
  ) {
    if (state.team?.distributionGroups?.length > 0 && !reset) {
      return
    }

    const { distributionGroups } = await ApiReports.getDistributionGroups()
    commit('SET_TEAM_DISTRIBUTION_GROUPS', distributionGroups)
  },

  async getTeamContacts({ commit, state }, reset = state.globalReset) {
    if (
      state.team &&
      state.team.teamContacts &&
      state.team.teamContacts.length > 0 &&
      !reset
    )
      return

    const { users } = await ApiTeams.getTeamContactUsers()
    commit('SET_TEAM_CONTACT_USERS', users)
  },

  async getAllUsers({ commit }) {
    const [teamUserInfo, teamAndInternal, teamContactUsers, teamUploadUsers] =
      await Promise.all([
        ApiTeams.getTeamUsers(),
        ApiTeams.getTeamUsers(true),
        ApiTeams.getTeamContactUsers(),
        ApiTeams.getTeamUploadUsers(),
      ])

    commit('SET_SESSION_INFO', { team: teamUserInfo })
    commit('SET_TEAM_AND_INTERNAL_USERS', teamAndInternal.users)
    commit('SET_TEAM_CONTACT_USERS', teamContactUsers.users)
    commit('SET_TEAM_UPLOAD_PORTAL_USERS', teamUploadUsers.users)
  },

  async getUserAlerts({ commit, state }, reset = state.globalReset) {
    if (state.alerts && state.alerts.length > 0 && !reset) return

    const alertInfo = await ApiUsers.getUserAlerts()
    commit('SET_ALERTS', alertInfo.data)
  },

  async getUserNavItems({ state }, reset = state.globalReset) {
    if (state.auth.userNavItems && !reset) return state.auth.userNavItems

    return await ApiUsers.getAvailableNavItems()
  },

  async getUserPermissionCode({ state }) {
    if (state.auth && state.auth.permissionCode)
      return state.auth.permissionCode

    return await ApiUsers.getUserPermissionCode()
  },

  async getUserPersonalizations({ commit, state }, reset = state.globalReset) {
    const userPersKeys =
      state.auth &&
      state.auth.personalizations &&
      Object.keys(state.auth.personalizations)

    if (userPersKeys && userPersKeys.length > 0 && !reset) return

    const { personalizations } = await ApiUsers.getPersonalizations()
    commit('SET_USER_PERSONALIZATIONS', personalizations)

    const expandedState = personalizations.global_left_nav_expanded_state
    const isExpanded = expandedState
      ? expandedState.parameters.isExpanded
      : false
    commit('TOGGLE_LEFT_NAV', isExpanded)

    const darkMode = personalizations.dark_mode
    const isDarkMode = darkMode ? darkMode.parameters.enabled : false
    commit('SET_DARK_MODE', isDarkMode)
  },

  async getTeamUserModulePermissions({ commit, state }) {
    if (state.auth.teamUserModulePermissions) return

    const { permissions } = await ApiUsers.getTeamModulePermissions()
    commit('SET_TEAM_USER_MODULE_PERMISSIONS', permissions)
  },

  async getUserSession({ state }, reset = state.globalReset) {
    if (state.session && !reset) return { session: state.session }

    return await ApiAuth.getSession()
  },

  async getAndSetUserSession(
    { commit, dispatch, state },
    reset = state.globalReset
  ) {
    const sessionInfo = await dispatch('getUserSession', reset)
    commit('SET_SESSION_INFO', {
      session: sessionInfo.session,
      user: sessionInfo.session.user,
    })
  },

  async getTags({ commit, state }, reset = state.globalReset) {
    if (state.tags && state.tags.length > 0 && !reset) return

    const res = await ApiTags.getAllTags()
    commit('SET_TAGS', res.tags)
  },

  async getR3STags({ commit, state }, reset = state.globalReset) {
    if (state.r3sTags && state.r3sTags.length > 0 && !reset) return

    const res = await ApiTags.getAllTags(1)
    commit('SET_R3S_TAGS', res.tags)
  },

  async getModulePermissionList({ commit, state }) {
    if (state.modulePermissionList && state.modulePermissionList.length > 0)
      return

    commit(
      'SET_MODULE_PERMISSION_LIST',
      await ApiGlobal.getModulePermissionList()
    )
  },

  async getStickyNotes({ commit, state }) {
    const { notes } = await ApiAdmin.getOrgStickyNotes(
      state.stickyNotesIncludeDeleted
    )
    commit('SET_STICKY_NOTES', notes)
  },

  async getAppOauthBindings({ commit, state }, reset = state.globalReset) {
    if (
      state.oauthBindings &&
      Object.values(state.oauthBindings).some((v) => !!v) &&
      !reset
    )
      return

    const bindings = await ApiGlobal.getOauthBindings()
    commit('SET_OAUTH_BINDINGS', bindings)
  },

  async getFeatureFlags({ commit, state }, reset = state.globalReset) {
    if (state.featureFlags && !reset) return

    const { features } = await ApiGlobal.getFeatureFlags()
    commit('SET_FEATURE_FLAGS', features)
  },

  async getTeamEnums({ commit, state }, reset = state.globalReset) {
    let err
    try {
      if (state.team.enums.length > 0 && !reset) return
      if (state.team.enumDebounce) return
      commit('SET_ENUM_DEBOUNCE', true)

      const { enums } = await ApiTeams.getAllEnums()
      commit('SET_TEAM_ENUMS', enums)
    } catch (e) {
      err = e
    } finally {
      commit('SET_ENUM_DEBOUNCE', false)
    }

    if (err) throw err
  },

  async getAwsKeys({ commit, state }, reset = state.globalReset) {
    if (state.awsKeys.length > 0 && !reset) return

    const { records } = await ApiAws.getApiKeys()
    commit('SET_API_KEYS', records)
  },
}
