import { createApp } from 'vue'
import createStore from './vuex/store'
import { router } from './router'

import PrimeVue from 'primevue/config'
import Tooltip from 'primevue/tooltip'
import { definePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'

import { Chart } from 'chart.js'
import 'bootstrap'
import 'timesheet.js/dist/timesheet.min.js'
import toastr from 'toastr'
import ApiAuth from './factories/ApiAuth'

import InnerDonutChartPlugin from './plugins/InnerDonutChartPlugin'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import './chartjsJitter'

import VueHighlightJS from './directives/vue-highlightjs'
import DecodeRecursive from './plugins/DecodeRecursive'
import Vue3Dragscroll from 'vue3-dragscroll'
import VueObserveVisibility from 'vue3-observe-visibility'
import vVisible from './directives/v-visible'
import vClickOutside from './directives/v-click-outside'
import uuidmixin from './plugins/uuidmixin'

import Risk3sixty from './components/Risk3sixty'
import mountReusables from './components/reusable'

const app = createApp(Risk3sixty)

const store = createStore(router)
app.use(store)

router.beforeEach(async (to, from, next) => {
  // Assume route requires auth unless explicitly stated not to in the metadata (/login)
  if (to.meta.requiresAuth === false || store.state.isLoggedIn) {
    next()
  } else {
    try {
      const { session } = await ApiAuth.getSession()
      const isLoggedIn = !!session.user
      store.commit('CHECK_LOGGED_IN', isLoggedIn)

      next(isLoggedIn ? undefined : '/login')
    } catch (err) {
      next('/login')
    }
  }
})
app.use(router)

const MyPreset = definePreset(Aura, {
  semantic: {
    primary: {
      50: '{black.50}',
      100: '{black.100}',
      200: '{black.200}',
      300: '{black.300}',
      400: '{black.400}',
      500: '{black.500}',
      600: '{black.600}',
      700: '{black.700}',
      800: '{black.800}',
      900: '{black.900}',
      950: '{black.950}',
    },
  },
})
app.use(PrimeVue, {
  theme: {
    preset: MyPreset,
    options: {
      darkModeSelector: '.dark',
    },
  },
})
app.directive('tooltip', Tooltip)

mountReusables(app)

app.config.performance = !!window.webpackHotUpdate

Chart.register(InnerDonutChartPlugin)
Chart.register(ChartDataLabels)
Chart.defaults.set('plugins.datalabels', {
  ...Chart.defaults.plugins.datalabels,
  color: 'rgba(0, 0, 0, 0)',
  formatter: function (value) {
    return value ? value : ''
  },
})

import R3sFetch from './factories/R3sFetch'
import { handleFetchResponse } from './factories/ApiHelpers'

// css
import './css/app.scss'
import 'timesheet.js/dist/timesheet-white.min.css'
import 'highlight.js/styles/default.css'
import 'highlight.js/styles/agate.css'
import 'vue-select/dist/vue-select.css'

app.mixin(uuidmixin)
app.use(DecodeRecursive)
app.use(Vue3Dragscroll)
app.use(VueObserveVisibility)
app.use(vVisible)
app.use(vClickOutside)
app.use(VueHighlightJS)

// Needed to make component `TypeaheadInput` work, see src at
// src/components/reusable/typeahead/typeahead.js
app.config.globalProperties.$http = {
  async get(src, { params }) {
    const queryStringDelim = src.includes('?') ? '&' : '?'
    if (params && params.search)
      src = `${src}${queryStringDelim}search=${params.search}`

    const response = await R3sFetch(src)
    const data = await handleFetchResponse(response)
    return { data }
  },
}

window.vueRouter = router
window.toastr = toastr
window.toastr.options.positionClass = 'toast-bottom-right toastr-bottom-margin'
window.toastr.options.escapeHtml = true // avoid xss

// overload decodeURIComponent to use "safeDecodeURIComponent"
window.decodeURIComponent = function safeDecodeURIComponent(uri) {
  try {
    return decodeURIComponent(uri)
  } catch (e) {
    return uri
  }
}

app.mount('#risk3sixty')

export default app
