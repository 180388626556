import ApiVendorManagement from '../../factories/ApiVendorManagement'

export default {
  async initVendorManagement({ dispatch, state }, reset = state.globalReset) {
    await Promise.all([
      dispatch('getVendors', reset),
      dispatch('getVendorStatuses', reset),
      dispatch('getVendorCategories', reset),
      dispatch('getVendorPolicies', reset),
      dispatch('getTeamVendors', reset),
      dispatch('getQuestionnaires', reset),
      dispatch('getAllQuestionnaires', reset),
      dispatch('getQuestionnaireTemplates', reset),
      dispatch('getQuestionnaireStatuses', reset),
      dispatch('getQuestionnaireChoiceTemplates', reset),
      dispatch('getReviews', reset),
      dispatch('getAllReviews', reset),
      dispatch('getReviewStatuses', reset),
    ])
  },

  async getAllQuestionnaireInfo(
    { dispatch, state },
    reset = state.globalReset
  ) {
    await Promise.all([
      dispatch('getAllQuestionnaires', reset),
      dispatch('getQuestionnaireTemplates', reset),
      dispatch('getQuestionnaireStatuses', reset),
      dispatch('getQuestionnaireChoiceTemplates', reset),
    ])
  },

  async getVendors({ commit, state }, reset = state.globalReset) {
    if (state.vendormanagement.vendors.length > 0 && !reset) return

    const { vendors } = await ApiVendorManagement.getVendors()
    commit('SET_VENDOR_MANAGEMENT_VENDORS', vendors)
  },

  async createUpdateVendor({ dispatch }, vendor) {
    const { id } = await ApiVendorManagement.createUpdateVendor(vendor)
    await Promise.all([
      dispatch('getVendors', true),
      dispatch('getTeamVendors', true),
    ])

    return id
  },

  async getVendorCategories({ commit, state }, reset = state.globalReset) {
    if (state.vendormanagement.categories.length > 0 && !reset) return

    const { categories } = await ApiVendorManagement.getVendorCategories()
    commit('SET_VENDOR_MANAGEMENT_CATEGORIES', categories)
  },

  async getVendorPolicies({ commit, state }, reset = state.globalReset) {
    if (state.vendormanagement.impactScores.length > 0 && !reset) return

    const { policies } = await ApiVendorManagement.getVendorPolicies()
    commit('SET_VENDOR_MANAGEMENT_POLICIES', policies)
  },

  async getTeamVendors({ commit, state }, reset = state.globalReset) {
    if (state.vendormanagement.teamVendors.totalCount > 0 && !reset) return

    const { info } = await ApiVendorManagement.getTeamVendors(
      state.vendormanagement.teamVendorFilters,
      state.vendormanagement.teamVendorSorting,
      state.vendormanagement.teamVendors.currentPage,
      state.vendormanagement.teamVendors.perPage
    )
    commit('SET_VENDOR_MANAGEMENT_TEAM_VENDORS', info)
  },

  async getVendorStatuses({ commit, state }, reset = state.globalReset) {
    if (state.vendormanagement.vendorStatuses.length > 0 && !reset) return

    const { statuses } = await ApiVendorManagement.getVendorStatuses()
    commit('SET_VENDOR_MANAGEMENT_VENDOR_STATUSES', statuses)
  },

  async setTeamVendorsFilter({ commit, dispatch }, { key, val }) {
    commit('SET_VENDOR_MANAGEMENT_TEAM_VENDOR_FILTER', { key, val })
    await dispatch('getTeamVendors', true)
  },

  async clearTeamVendorsFilters({ commit, dispatch }) {
    commit('CLEAR_VENDOR_MANAGEMENT_TEAM_VENDOR_FILTERS')
    await dispatch('getTeamVendors', true)
  },

  async clearTeamVendorsSorting({ commit, dispatch }) {
    commit('RESET_VENDOR_MANAGEMENT_TEAM_VENDOR_SORTING')
    await dispatch('getTeamVendors', true)
  },

  async setTeamVendorsSorting({ commit, dispatch }, { key, val }) {
    commit('SET_VENDOR_MANAGEMENT_TEAM_VENDOR_SORTING', { key, val })
    dispatch('getTeamVendors', true)
  },

  async setTeamVendorsChangePage({ commit, dispatch }, newPage) {
    commit('SET_VENDOR_MANAGEMENT_TEAM_VENDORS_PAGE', newPage)
    await dispatch('getTeamVendors', true)
  },

  async setTeamVendorsChangePerPage({ commit, dispatch }, newPerPage) {
    commit('SET_VENDOR_MANAGEMENT_TEAM_VENDORS_PAGE', 1)
    commit('SET_VENDOR_MANAGEMENT_TEAM_VENDORS_PER_PAGE', newPerPage)
    await dispatch('getTeamVendors', true)
  },

  async getTeamVendor({ commit }, vendorId) {
    const { vendor } = await ApiVendorManagement.getTeamVendor(vendorId)
    commit('SET_VENDOR_MANAGEMENT_CURRENT_VENDOR', vendor)
  },

  async createUpdateTeamVendor({ dispatch }, vendor) {
    const { id } = await ApiVendorManagement.createUpdateTeamVendor(vendor)
    await dispatch('getTeamVendors', true)

    return id
  },

  async getReviews({ commit, state }, reset = state.globalReset) {
    if (state.vendormanagement.reviews.totalCount > 0 && !reset) return

    const { info } = await ApiVendorManagement.getReviews(
      state.vendormanagement.reviewFilters,
      state.vendormanagement.reviews.currentPage,
      state.vendormanagement.reviews.perPage
    )
    commit('SET_VENDOR_MANAGEMENT_REVIEWS', info)
  },

  async getAllReviews({ commit, state }, reset = state.globalReset) {
    if (state.vendormanagement.allReviews.totalCount > 0 && !reset) return

    const { info } = await ApiVendorManagement.getReviews()
    commit('SET_VENDOR_MANAGEMENT_ALL_REVIEWS', info)
  },

  async setReviewsFilter({ commit, dispatch }, { key, val }) {
    commit('SET_VENDOR_MANAGEMENT_REVIEWS_FILTER', { key, val })
    await dispatch('getReviews', true)
  },

  async clearReviewsFilters({ commit, dispatch }) {
    commit('CLEAR_VENDOR_MANAGEMENT_REVIEWS_FILTERS')
    await dispatch('getReviews', true)
  },

  async setReviewsChangePage({ commit, dispatch }, newPage) {
    commit('SET_VENDOR_MANAGEMENT_REVIEWS_PAGE', newPage)
    await dispatch('getReviews', true)
  },

  async setReviewsChangePerPage({ commit, dispatch }, newPerPage) {
    commit('SET_VENDOR_MANAGEMENT_REVIEWS_PAGE', 1)
    commit('SET_VENDOR_MANAGEMENT_REVIEWS_PER_PAGE', newPerPage)
    await dispatch('getReviews', true)
  },

  async getReview({ commit }, reviewId) {
    const { review } = await ApiVendorManagement.getReview(reviewId)
    commit('SET_VENDOR_MANAGEMENT_CURRENT_REVIEW', review)
  },

  async createUpdateReview({ dispatch }, review) {
    const { id } = await ApiVendorManagement.createUpdateReview(review)
    await dispatch('getTeamVendors', true)

    return id
  },

  async getReviewStatuses({ commit, state }, reset = state.globalReset) {
    if (state.vendormanagement.reviewStatuses.length > 0 && !reset) return

    const { statuses } = await ApiVendorManagement.getReviewStatuses()
    commit('SET_VENDOR_MANAGEMENT_REVIEW_STATUSES', statuses)
  },

  async getQuestionnaires({ commit, state }, reset = state.globalReset) {
    if (state.vendormanagement.questionnaires.totalCount > 0 && !reset) return

    const { info } = await ApiVendorManagement.getQuestionnaires(
      null,
      state.vendormanagement.questionnaireFilters,
      state.vendormanagement.questionnaires.currentPage,
      state.vendormanagement.questionnaires.perPage
    )
    commit('SET_VENDOR_MANAGEMENT_QUESTIONNAIRES', info)
  },

  async getAllQuestionnaires({ commit, state }, reset = state.globalReset) {
    if (state.vendormanagement.allQuestionnaires.totalCount > 0 && !reset)
      return

    const { info } = await ApiVendorManagement.getQuestionnaires()
    commit('SET_VENDOR_MANAGEMENT_ALL_QUESTIONNAIRES', info)
  },

  async getQuestionnaireTemplates(
    { commit, state },
    reset = state.globalReset
  ) {
    if (state.vendormanagement.questionnaireTemplates.totalCount > 0 && !reset)
      return

    const { info } = await ApiVendorManagement.getQuestionnaireTemplates(
      null,
      state.vendormanagement.questionnaireFilters,
      state.vendormanagement.questionnaireTemplates.currentPage,
      state.vendormanagement.questionnaireTemplates.perPage
    )
    commit('SET_VENDOR_MANAGEMENT_QUESTIONNAIRE_TEMPLATES', info)
  },

  async setQuestionnairesFilter({ commit, dispatch }, { key, val }) {
    commit('SET_VENDOR_MANAGEMENT_QUESTIONNAIRES_FILTER', { key, val })
    await dispatch('getQuestionnaires', true)
  },

  async setQuestionnairesChangePage({ commit, dispatch }, newPage) {
    commit('SET_VENDOR_MANAGEMENT_QUESTIONNAIRES_PAGE', newPage)
    await dispatch('getQuestionnaires', true)
  },

  async setQuestionnairesChangePerPage({ commit, dispatch }, newPerPage) {
    commit('SET_VENDOR_MANAGEMENT_QUESTIONNAIRES_PAGE', 1)
    commit('SET_VENDOR_MANAGEMENT_QUESTIONNAIRES_PER_PAGE', newPerPage)
    await dispatch('getQuestionnaires', true)
  },

  async getQuestionnaire({ commit }, questionnaireId) {
    const { questionnaire } = await ApiVendorManagement.getQuestionnaire(
      questionnaireId
    )
    commit('SET_VENDOR_MANAGEMENT_CURRENT_QUESTIONNAIRE', questionnaire)
  },

  async getQuestionnaireAndSections({ commit }, questionnaireId) {
    const { questionnaire } =
      await ApiVendorManagement.getQuestionnaireSectionsQuestions(
        questionnaireId
      )
    commit('SET_VENDOR_MANAGEMENT_QUESTIONNAIRE_AND_SECTIONS', questionnaire)
  },

  async getQuestionnaireStatuses({ commit, state }, reset = state.globalReset) {
    if (state.vendormanagement.questionnaireStatuses.length > 0 && !reset)
      return

    const { statuses } = await ApiVendorManagement.getQuestionnaireStatuses()
    commit('SET_VENDOR_MANAGEMENT_QUESTIONNAIRE_STATUSES', statuses)
  },

  async createUpdateQuestionnaire({ dispatch }, questionnaire) {
    const { id } = await ApiVendorManagement.createUpdateQuestionnaire(
      questionnaire
    )
    await dispatch('getQuestionnaires', true)

    return id
  },

  async createUpdateQuestionnaireRetake({ dispatch }, questionnaire) {
    const { id } = await ApiVendorManagement.createUpdateQuestionnaireRetake(
      questionnaire
    )
    await dispatch('getQuestionnaires', true)

    return id
  },

  async createQuestionnaireTemplate({ dispatch }, questionnaireId) {
    const { id } = await ApiVendorManagement.createQuestionnaireTemplate(
      questionnaireId
    )
    await dispatch('getQuestionnaireTemplates', true)

    return id
  },

  async getQuestionnaireChoiceTemplates(
    { commit, state },
    reset = state.globalReset
  ) {
    if (
      state.vendormanagement.questionnaireChoiceTemplates.length > 0 &&
      !reset
    )
      return

    const { templates } =
      await ApiVendorManagement.getQuestionnaireChoiceTemplates()
    commit('SET_VENDOR_MANAGEMENT_CHOICE_TEMPLATES', templates)
  },
}
